.container {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 1px;
    font-size: 14px;

    @media (max-width: 768px) {
      min-width: 768px;
    }
  }

  table,
  th,
  td {
    border: 1px solid #999;
  }
  th {
    padding: 8px 8px;
    text-align: left;
  }

  td {
    padding: 8px 8px;
    text-align: left;
    font-size: 13px;
    /* min-width: 120px; */
  }

  thead th {
    font-weight: 700;
    background-color: #fff;
    /* min-width: 120px; */
  }



  thead th div#header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  tbody tr {
    background-color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  tbody tr:hover {
    background-color: #e5e5e5;
  }



}

.action_header {
  text-align: center;
}


.action_body {
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    button {
      background: none;
      border: none;
      display: flex;
      justify-content: center;


    }



    svg {
      color: #898c90;
      transition: color 0.2s;

      &:hover {
        color: #333;
      }
    }
  }
}

.filter_panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  padding: 4px;
  width: 100%;

  @media (max-width: 768px) {
    min-width: 768px;
  }
}

.status_bar {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    min-width: 768px;
  }
}

.total_panel {
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
}

.container_export_csv {
  display: flex;
  min-width: 240px;
  align-items: flex-end;
  justify-content: center;
  height: 55px;
  padding-right: 8px;
}

.container_por_pagina {
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.por_pagina_container_selected {
  width: 70px;

}

.por_pagina_container_text {
  width: 82px;
  margin-left: 8px;
}

.row_loading {
  width: 100%;
  height: 80px;
  div {

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
